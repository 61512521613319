export const CONTROLS_WRAPPER = {
  REF: "CONTROLS_WRAPPER",
  CLASS: "player-controls-wrapper",
  ID: "player-controls-wrapper"
};

export const TOP_CONTROLS = {
  REF: "TOP_CONTROLS",
  CLASS: "top-controls",
  ID: "top-controls"
};

export const TOP_CONTROLS_RIGHT = {
  REF: "TOP_CONTROLS_RIGHT",
  CLASS: "top-controls-right",
  ID: "top-controls-right"
};

export const TOP_CONTROLS_CENTER = {
  REF: "TOP_CONTROLS_CENTER",
  CLASS: "top-controls-center",
  ID: "top-controls-center"
};

export const TOP_CONTROLS_LEFT = {
  REF: "TOP_CONTROLS_LEFT",
  CLASS: "top-controls-left",
  ID: "top-controls-left"
};

export const BACK_BUTTON = {
  REF: "BACK_BUTTON",
  CLASS: "back-button",
  ID: "back-button"
};
