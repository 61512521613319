export default class InteractorMethods {
  play = () => this.playerObj.play();

  pause = () => this.playerObj.pause();

  currentTime = () => this.playerObj.currentTime();

  seek = (val) => this.playerObj.seek(val);

  muted = (val) => this.playerObj.muted(val);

  volume = (val) => {
    if (val) {
      return this.playerObj.setVolume(val);
    }
    return this.playerObj.getVolume();
  };

  load = () => { };

  isLive = () => { };

  isStalled = () => {
    const player = this.playerObj.player.playerDom;
    return player.readyState < player.HAVE_FUTURE_DATA;
  };

  fastSeekToPosition = (time) => {
    const player = this.playerObj.player.playerDom;
    const start = player.seekable.start(0);
    const end = player.seekable.end(0);
    if (time < end && time > start) {
      this.playerObj.seek(time);
    }
  };

  isPlaying = () => !this.playerObj.paused();

  getQualityLevels = () => this.playerObj.getQualityLevels();

  setQualityLevel = (bitrate) => this.playerObj.setQualityLevel(bitrate);

  getAudioTracks = () => this.playerObj.getAudioTracks();

  setAudioTrack = (id) => this.playerObj.setAudioTrack(id);

  // get video() {
  //   return this.playerObj.player.playerDom;
  // }
}
