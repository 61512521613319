export default class DataFormatter {
  constructor(playerMetadata) {
    this.setDataModel(playerMetadata);
  }

  setDataModel = (playerMetadata) => {
    this.dataModel = {};
    this.dataModel.url = playerMetadata.url;
    this.dataModel.isLive = playerMetadata.isLive;
    this.dataModel.licenseUrl = playerMetadata.licenseUrl;
    this.dataModel.isEncrypted = playerMetadata.isEncrypted;
    this.dataModel.customHeaders = playerMetadata.customHeaders;
  };
}
