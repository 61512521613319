import { createDOMElement } from "../../../utils/player.utils";
import {
  CONTROLS_WRAPPER,
  TOP_CONTROLS,
  TOP_CONTROLS_CENTER,
  TOP_CONTROLS_LEFT,
  TOP_CONTROLS_RIGHT
} from "../player.ui.constants";

export default class ControlsWrapper {
  constructor(currentUI) {
    this.currentUI = currentUI;
    this.elementRef = {};
    this.render();
  }

  get interactor() {
    return this.currentUI.interactor;
  }

  get videoPlayerWrapper() {
    return this.currentUI.videoPlayerWrapper;
  }

  get dataModel() {
    return this.interactor.dataModel;
  }

  get eventManager() {
    return this.interactor.eventManager;
  }

  render = () => {
    const controlsWrapper = createDOMElement({
      ele: "div",
      attributes: {
        id: CONTROLS_WRAPPER.ID,
        class: CONTROLS_WRAPPER.CLASS
      }
    });
    const topControls = createDOMElement({
      ele: "div",
      attributes: {
        id: TOP_CONTROLS.ID,
        class: TOP_CONTROLS.CLASS
      }
    });
    const topControlsLeft = createDOMElement({
      ele: "div",
      attributes: {
        id: TOP_CONTROLS_LEFT.ID,
        class: TOP_CONTROLS_LEFT.CLASS
      }
    });
    const topControlsCenter = createDOMElement({
      ele: "div",
      attributes: {
        id: TOP_CONTROLS_CENTER.ID,
        class: TOP_CONTROLS_CENTER.CLASS
      }
    });
    const topControlsRight = createDOMElement({
      ele: "div",
      attributes: {
        id: TOP_CONTROLS_RIGHT.ID,
        class: TOP_CONTROLS_RIGHT.CLASS
      }
    });
    topControls.appendChild(topControlsLeft);
    topControls.appendChild(topControlsCenter);
    topControls.appendChild(topControlsRight);
    controlsWrapper.appendChild(topControls);
    this.videoPlayerWrapper.appendChild(controlsWrapper);

    this.elementRef[TOP_CONTROLS.REF] = topControls;
    this.elementRef[TOP_CONTROLS_LEFT.REF] = topControlsLeft;
    this.elementRef[TOP_CONTROLS_CENTER.REF] = topControlsCenter;
    this.elementRef[TOP_CONTROLS_RIGHT.REF] = topControlsRight;
    this.elementRef[CONTROLS_WRAPPER.REF] = controlsWrapper;
  };

  destroy = () => {
    this.elementRef = {};
  };
}
