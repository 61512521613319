import { EVENT_CALLBACK, NATIVE_EVENTS } from "../ui/player/player.events";

export default class EventManager {
  constructor() {
    this.playerCallbackSubscriber = [];
    this.customCallbackSubscriber = [];
    this.adsCallbackSubscriber = [];
    this.verboseEventsList = [
      NATIVE_EVENTS.SEEKBAR_UPDATE,
      NATIVE_EVENTS.TIMEUPDATE
    ];
  }

  unSubscribeToEvents = (componentName) => {
    this.playerCallbackSubscriber = this.playerCallbackSubscriber.filter(
      (subscriber) => subscriber.senderName !== componentName
    );
    this.customCallbackSubscriber = this.customCallbackSubscriber.filter(
      (subscriber) => subscriber.senderName !== componentName
    );
    this.adsCallbackSubscriber = this.adsCallbackSubscriber.filter(
      (subscriber) => subscriber.senderName !== componentName
    );
  };

  subscribeToEvents({
    eventType,
    eventCallback,
    senderName = "",
    isSync = false,
    needVerbose = true
  }) {
    switch (eventType) {
      case EVENT_CALLBACK.NATIVE_EVENTS:
        this.playerCallbackSubscriber.push({
          eventCallback,
          senderName,
          isSync,
          needVerbose
        });
        break;
      case EVENT_CALLBACK.CUSTOM_EVENTS:
        this.customCallbackSubscriber.push({
          eventCallback,
          senderName,
          isSync,
          needVerbose
        });
        break;
      case EVENT_CALLBACK.AD_EVENTS:
        this.adsCallbackSubscriber.push({
          eventCallback,
          senderName,
          isSync,
          needVerbose
        });
        break;
    }
  }

  shouldSkipEvent = (eachSubscriber, eventName) => {
    if (eachSubscriber.needVerbose) {
      return false;
    }
    if (this.verboseEventsList.includes(eventName)) {
      return true;
    }
    return false;
  };

  handleSubscriber = (subscriberArray, eventName, eventData) => {
    for (let i = 0; i < subscriberArray.length; i++) {
      const subscriber = subscriberArray[i];
      const shouldSkipEvent = this.shouldSkipEvent(
        subscriber,
        eventName,
        subscriber.senderName
      );
      if (shouldSkipEvent) {
        // logger.log("skip Events", subscriber.senderName);
      } else if (typeof subscriber.eventCallback === "function") {
        if (subscriber.isSync || eventData?.isSync) {
          subscriber.eventCallback(eventName, eventData);
        } else {
          ((eName, eData) => {
            setTimeout(() => {
              subscriber.eventCallback(eName, eData);
            }, 0);
          })(eventName, eventData);
        }
      }
    }
  };

  broadcastNativeEvent = (event, eventData) => {
    this.handleSubscriber(this.playerCallbackSubscriber, event, eventData);
  };

  broadcastCustomEvent = (event, eventData) => {
    this.handleSubscriber(this.customCallbackSubscriber, event, eventData);
  };

  broadcastAdEvent = (event, eventData) => {
    this.handleSubscriber(this.adsCallbackSubscriber, event, eventData);
  };
}
