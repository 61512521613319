import { createDOMElement } from "../../../utils/player.utils";
import { BACK_BUTTON, TOP_CONTROLS_LEFT } from "../player.ui.constants";

export default class BackButton {
  constructor(currentUI) {
    this.currentUI = currentUI;
    this.elementRef = {};
    this.render();
  }

  get interactor() {
    return this.currentUI.interactor;
  }

  get videoPlayerWrapper() {
    return this.currentUI.videoPlayerWrapper;
  }

  get dataModel() {
    return this.interactor.dataModel;
  }

  get eventManager() {
    return this.interactor.eventManager;
  }

  render = () => {
    const parentElement =
      this.currentUI.controlsWrapper.elementRef[TOP_CONTROLS_LEFT.REF];

    const backButton = createDOMElement({
      ele: "div",
      attributes: {
        tabindex: 0,
        id: BACK_BUTTON.ID,
        class: BACK_BUTTON.CLASS
      },
      innerText: "Back"
    });

    parentElement.appendChild(backButton);
    this.elementRef[BACK_BUTTON.REF] = backButton;
  };

  destroy = () => {
    this.elementRef = {};
  };
}
