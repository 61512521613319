export const BROWSER_NAME = {
  MOBILE_SAFARI: "Mobile Safari",
  SAFARI: "Safari",
  CHROME: "Chrome",
  IE: "IE"
};

export const KEY_SYSTEMS = {
  fairPlay: "com.apple.fps.1_0",
  playReady: "com.microsoft.playready",
  widevine: "com.widevine.alpha"
};
