import PlayerInterface from "./player.interface";
import VideoJSPlayer from "./player.videojs";

export default class PlayerWrapper extends PlayerInterface {
  constructor(options) {
    super();
    this.player = null;
    this.options = options;
    this.init(this.options);
  }

  get interactor() {
    return this.options.interactor;
  }

  get dataModel() {
    return this.interactor.dataModel;
  }

  init = (options) => {
    this.player = new VideoJSPlayer(options);
    this.player.init();
  };

  play = () => {
    if (this.player) {
      // this.seekToLiveEdge();
      return this.player.play();
    }
    return null;
  };

  pause = () => {
    if (this.player) {
      return this.player.pause();
    }
    return null;
  };

  forward = (time) => {
    if (this.player) {
      return this.player.forward(time);
    }
    return null;
  };

  rewind = (time) => {
    if (this.player) {
      return this.player.rewind(time);
    }
    return null;
  };

  seek = (time) => {
    if (this.player) {
      return this.player.seek(time);
    }
    return null;
  };

  stop = () => {
    if (this.player) {
      return this.player.stop();
    }
    return null;
  };

  destroy = () => {
    if (this.player) {
      return this.player.destroy();
    }
    return null;
  };

  muted = (value) => {
    if (this.player) {
      return this.player.muted(value);
    }
    return null;
  };

  paused = () => {
    if (this.player) {
      return this.player.paused();
    }
    return null;
  };

  buffered = () => {
    if (this.player) {
      return this.player.buffered();
    }
    return null;
  };

  duration = () => {
    if (this.player) {
      return this.player.duration();
    }
    return null;
  };

  currentTime = () => {
    if (this.player) {
      return this.player.currentTime();
    }
    return null;
  };

  getBuffered = () => {
    if (this.player) {
      return this.player.getBuffered();
    }
    return null;
  };

  setVolume = (volume) => {
    if (this.player) {
      return this.player.setVolume(volume);
    }
    return null;
  };

  getVolume = () => {
    if (this.player) {
      return this.player.getVolume();
    }
    return null;
  };

  setAudioTrack = (audioTrack) => {
    if (this.player) {
      return this.player.setAudioTrack(audioTrack);
    }
    return null;
  };

  getAudioTracks = () => {
    if (this.player) {
      return this.player.getAudioTracks();
    }
    return null;
  };

  getActiveAudioTrack = () => {
    if (this.player) {
      return this.player.getActiveAudioTrack();
    }
    return null;
  };

  setQualityLevel = (bitrate) => {
    if (this.player) {
      return this.player.setQualityLevel(bitrate);
    }
    return null;
  };

  getQualityLevels = () => {
    if (this.player) {
      return this.player.getQualityLevels();
    }
    return null;
  };

  getActiveQualityLevel = () => {
    if (this.player) {
      return this.player.getActiveQualityLevel();
    }
    return null;
  };

  seekToLiveEdge = () => {
    if (this.player) {
      if (this.dataModel.isLive) {
        return this.player.seekToLiveEdge();
      }
    }
    return null;
  };
}
