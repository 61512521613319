import BackButton from "./backButton";
import ControlsWrapper from "./controlsWrapper";
import "./index.scss";

export default class PlayerUI {
  constructor(options) {
    this.options = options;
    this.elementRef = {};
    this.init();
  }

  get currentUI() {
    return this;
  }

  get interactor() {
    return this.options.interactor;
  }

  get videoPlayerWrapper() {
    return this.options.videoPlayerWrapper;
  }

  get dataModel() {
    return this.interactor.dataModel;
  }

  get eventManager() {
    return this.interactor.eventManager;
  }

  init = () => {
    this.controlsWrapper = new ControlsWrapper(this.currentUI);
    this.backButton = new BackButton(this.currentUI);
  };

  destroy = () => {
    this.backButton.destroy();
    this.controlsWrapper.destroy();
  };
}
