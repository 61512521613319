import videojs from "video.js";
import { BROWSER_NAME, KEY_SYSTEMS } from "../ui/player/player.enums";
import { CUSTOM_EVENTS, NATIVE_EVENTS } from "../ui/player/player.events";
import {
  contentType,
  getPlatform,
  logger,
  mimeType
} from "../utils/player.utils";
import PlayerInterface from "./player.interface";

require("videojs-contrib-eme");
require("videojs-contrib-quality-levels");

export default class VideoJSPlayer extends PlayerInterface {
  constructor(options) {
    super();
    this.options = options;
    this.playerDom = null;
    this.playerRef = null;
  }

  get interactor() {
    return this.options.interactor;
  }

  get dataModel() {
    return this.interactor.dataModel;
  }

  get eventManager() {
    return this.interactor.eventManager;
  }

  get broadcastCustomEvent() {
    return this.eventManager.broadcastCustomEvent;
  }

  get broadcastNativeEvent() {
    return this.eventManager.broadcastNativeEvent;
  }

  init = () => {
    const type = contentType(this.options.url);
    switch (type) {
      case "MP4":
        this._createHtml5Video(this.options);
        break;
      case "WEBINITIATOR":
        this._createHtml5VideoWithWebInitiator(this.options);
        break;
      case "HLS":
      case "DASH":
        this._createVideojsPlayer(this.options);
        break;
    }
  };

  _createHtml5Video(options) {
    const {
      videoPlayerWrapper,
      url,
      controls,
      muted,
      videoTag,
      preload,
      autoplay
    } = options;
    const playerDom = videoTag || document.createElement("video");
    const source = document.createElement("source");
    source.src = url;
    source.type = mimeType(url);
    playerDom.appendChild(source);
    playerDom.controls = controls !== undefined ? autoplay : false;
    playerDom.autoplay = autoplay !== undefined ? autoplay : false;
    playerDom.muted = muted || false;
    playerDom.preload = preload || "auto";
    playerDom.style.position = "absolute";
    playerDom.style.left = 0;
    playerDom.style.top = 0;
    playerDom.style.display = "block";
    playerDom.style.width = "100%";
    playerDom.style.height = "100%";
    playerDom.style.backgroundColor = "#000";
    playerDom.crossOrigin = "anonymous";
    this.playerDom = playerDom;
    videoPlayerWrapper.appendChild(playerDom);
    this._bindPlayerEvents({
      shouldInit: true
    });
  }

  _createHtml5VideoWithWebInitiator(options) {
    const { url, preload, autoplay } = options;
    const playerDom = document.createElement("video");
    const source = document.createElement("source");
    source.src = url;
    source.type = mimeType(url);
    playerDom.appendChild(source);
    playerDom.controls = false;
    playerDom.autoplay = autoplay !== undefined ? autoplay : false;
    playerDom.preload = preload || "auto";
    playerDom.style.position = "absolute";
    playerDom.style.left = 0;
    playerDom.style.top = 0;
    playerDom.style.width = "100%";
    playerDom.style.height = "100%";
    playerDom.style.backgroundColor = "#000000";
    playerDom.crossOrigin = "anonymous";
    this.playerDom = playerDom;
    this.videoPlayerWrapper.appendChild(playerDom);
    this._bindPlayerEvents({
      shouldInit: true
    });
  }

  _createVideojsPlayer(options) {
    const {
      isEncrypted,
      preload,
      muted,
      videoPlayerWrapper,
      videoId,
      videoTag,
      autoplay
    } = options;
    const playerDom = videoTag || document.createElement("video");
    playerDom.setAttribute("id", videoId);
    playerDom.setAttribute("controls", false);
    playerDom.setAttribute("playsinline", "playsinline");
    playerDom.muted = !!muted;
    playerDom.autoplay = autoplay !== undefined ? autoplay : false;
    playerDom.style.position = "absolute";
    playerDom.style.left = 0;
    playerDom.style.top = 0;
    playerDom.style.display = "block";
    playerDom.style.width = "100%";
    playerDom.style.height = "100%";
    playerDom.style.zIndex = 1;
    playerDom.style.backgroundColor = "#000000";
    if (preload) {
      playerDom.preload = preload;
    } else {
      playerDom.preload = "auto";
    }
    playerDom.crossOrigin = "anonymous";
    this.playerDom = playerDom;
    videoPlayerWrapper.appendChild(playerDom);

    let playerOptions = this._getPlayerOptions(options);
    const vjsOptions = {
      autoplay: autoplay !== undefined ? autoplay : false,
      bigPlayButton: false,
      controls: false,
      controlBar: false,
      errorDisplay: false,
      loadingSpinner: false,
      posterImage: false,
      textTrackDisplay: false,
      textTrackSettings: false
    };

    playerOptions = {
      ...playerOptions,
      ...vjsOptions
    };

    this.playerRef = videojs(this.playerDom, playerOptions, () => {
      const playerDomRef = document.getElementById(this.playerDom.id);
      if (playerDomRef) {
        this.playerDom = playerDomRef;
      }
      this._bindPlayerEvents({
        shouldInit: true
      });
      this._subscribeVideoJsEvents();
      if (isEncrypted) {
        this.playerRef.eme();
      }
      this._setCustomHeaders(options);
      this.playerRef.src(this._getPlayerSourceConfig(options));
    });
  }

  _getPlayerOptions(options) {
    const { muted } = options;
    const playerOptions = {
      muted: !!muted,
      html5: {
        nativeTextTracks: false,
        nativeAudioTracks: false,
        nativeVideoTracks: false
      }
    };
    if (videojs.Vhs) {
      playerOptions.html5.vhs = {
        overrideNative: true,
        fastQualityChange: true,
        enableLowInitialPlaylist: false,
        limitRenditionByPlayerDimensions: false
      };
    } else if (videojs.Hls) {
      playerOptions.html5.hls = {
        overrideNative: true,
        fastQualityChange: true,
        enableLowInitialPlaylist: false,
        limitRenditionByPlayerDimensions: false
      };
      if (this.dataModel.isLive) {
        playerOptions.html5.hls.fastQualityChange = true;
      }
    }
    return playerOptions;
  }

  _getPlayerSourceConfig(options) {
    const { url, isEncrypted } = options;
    const sourceConfig = {};
    sourceConfig.src = url;
    sourceConfig.type = mimeType(url);
    // const cType = contentType(url);
    // if (cType === "HLS") {
    // } else if (cType === "DASH") {
    // }
    if (isEncrypted) {
      sourceConfig.keySystems = this._setDRMConfig(options);
    }
    return sourceConfig;
  }

  // eslint-disable-next-line class-methods-use-this
  _setDRMConfig(options) {
    const { licenseUrl } = options;
    let keySystems = {};
    const browser = getPlatform().browserName;
    if (
      browser === BROWSER_NAME.MOBILE_SAFARI ||
      browser === BROWSER_NAME.SAFARI
    ) {
      keySystems = {
        [KEY_SYSTEMS.fairPlay]: {
          certificateUri:
            "https://expressplay-test.s3.amazonaws.com/testplayer/fairplay-sony.cer",
          licenseUri: licenseUrl
        }
      };
    } else if (browser === BROWSER_NAME.IE) {
      keySystems = {
        [KEY_SYSTEMS.playReady]: licenseUrl
      };
    } else {
      keySystems = {
        [KEY_SYSTEMS.widevine]: licenseUrl
      };
    }
    return keySystems;
  }

  // eslint-disable-next-line class-methods-use-this
  _setCustomHeaders = (options) => {
    const { customHeaders } = options;
    if (customHeaders) {
      if (typeof videojs.Vhs !== "undefined") {
        videojs.Vhs.xhr.beforeRequest = (opt) => {
          const conf = opt;
          if (!conf.headers) {
            conf.headers = {};
          }
          Object.keys(customHeaders).forEach((key) => {
            conf.headers[key] = customHeaders[key];
          });
          return conf;
        };
      } else {
        videojs.Hls.xhr.beforeRequest = (opt) => {
          const conf = opt;
          if (!conf.headers) {
            conf.headers = {};
          }
          Object.keys(customHeaders).forEach((key) => {
            conf.headers[key] = customHeaders[key];
          });
          return conf;
        };
      }
    }
  };

  _bindPlayerEvents(config) {
    if (this.playerDom) {
      if (config.shouldInit) {
        this._onInit();
      }
      this.playerDom.onloadedmetadata = this._onLoadedMetaData;
      this.playerDom.onloadeddata = this._onLoadedData;
      this.playerDom.onplay = this._onPlay;
      this.playerDom.onpause = this._onPause;
      this.playerDom.ontimeupdate = this._onTimeUpdate;
      this.playerDom.onwaiting = this._onWaiting;
      this.playerDom.onseeking = this._onSeeking;
      this.playerDom.onseeked = this._onSeeked;
      this.playerDom.onplaying = this._onPlaying;
      this.playerDom.onended = this._onEnded;
      this.playerDom.onerror = this._onPlaybackError;
      this.playerDom.onloadstart = this._onLoadStart;
      this.playerDom.onprogress = this._onProgress;
      this.playerDom.onsuspend = this._onSuspend;
      this.playerDom.ondurationchange = this._onDurationChange;
      this.playerDom.onvolumechange = this._onVolumeChange;
      this.playerDom.onratechange = this._onRateChange;
      this.playerDom.onabort = this._onAbort;
      this.playerDom.onresize = this._onResize;
      this.playerDom.onstalled = this._onStalled;
    }
  }

  _unbindPlayerEvents = () => {
    if (this.playerDom) {
      this.playerDom.onloadedmetadata = null;
      this.playerDom.onloadeddata = null;
      this.playerDom.onplay = null;
      this.playerDom.onpause = null;
      this.playerDom.ontimeupdate = null;
      this.playerDom.onwaiting = null;
      this.playerDom.onseeking = null;
      this.playerDom.onseeked = null;
      this.playerDom.onplaying = null;
      this.playerDom.onended = null;
      this.playerDom.onerror = null;
      this.playerDom.onloadstart = null;
      this.playerDom.onprogress = null;
      this.playerDom.onsuspend = null;
      this.playerDom.ondurationchange = null;
      this.playerDom.onvolumechange = null;
      this.playerDom.onratechange = null;
      this.playerDom.onabort = null;
      this.playerDom.onresize = null;
      this.playerDom.onstalled = null;
      this.playerDom = null;
    }
  };

  _subscribeVideoJsEvents() {
    if (this.playerRef) {
      this.playerRef.textTracks().on("addtrack", this._onAddtrack);
      this.playerRef.audioTracks().on("change", this._onAudioChange);
    }
  }

  _onAddtrack = (e) => {
    const { track } = e;
    const onCueChange = () => {
      const elemTrack = track.activeCues[0];
      if (elemTrack && elemTrack.value.data) {
        const metadata = {};
        metadata[elemTrack.value.key] = elemTrack.value.data;
        metadata.duration = Infinity;
        this.broadcastCustomEvent(CUSTOM_EVENTS.TIMEDMETADATA, metadata);
      }
    };
    if (track && track.kind === "metadata") {
      if (typeof track.on === "function") {
        track.on("cuechange", onCueChange);
      } else {
        track.oncuechange = onCueChange;
      }
    }
  };

  _onAudioChange = () => {
    if (this.broadcastNativeEvent) {
      const enabledTrack = this.getAudioTracks().find((track) => track.enabled);
      this.broadcastNativeEvent(NATIVE_EVENTS.AUDIO_CHANGE, enabledTrack);
    }
  };

  _onInit = () => {
    if (this.broadcastNativeEvent && this.options.autoplay) {
      this.broadcastNativeEvent(NATIVE_EVENTS.INIT);
    }
  };

  _onLoadedMetaData = (e) => {
    this.prevTime = 0;
    if (this.broadcastNativeEvent) {
      e.currentTime = this.playerDom.currentTime;
      e.duration = this.playerDom.duration;
      this.broadcastNativeEvent(e.type, e);
    }
  };

  _onLoadedData = (e) => {
    if (this.broadcastNativeEvent) {
      this.isFirstLoadedDataReceived = true;
      e.currentTime = this.playerDom.currentTime;
      e.duration = this.playerDom.duration;
      this.broadcastNativeEvent(e.type, e);
      if (this.player) {
        const qualityLevels = this.player.qualityLevels();
        // this.setMaxBitrateCapping(qualityLevels);
        qualityLevels.on("change", this._onQualityChanged);
        this._onQualityChanged();
      }
    }
  };

  _onPlay = (e) => {
    if (this.broadcastNativeEvent) {
      this.broadcastNativeEvent(e.type, e);
    }
  };

  _onPause = (e) => {
    if (this.broadcastNativeEvent) {
      this.broadcastNativeEvent(e.type, e);
    }
  };

  _onTimeUpdate = (e) => {
    if (this.broadcastNativeEvent) {
      const { duration, currentTime } = this.playerDom;
      e.currentTime = this.playerDom.currentTime;
      e.duration = this.playerDom.duration;
      this.broadcastNativeEvent(NATIVE_EVENTS.SEEKBAR_UPDATE, e);
      const parsedCurrentTime = parseInt(currentTime, 10);
      if (this.prevTime !== parsedCurrentTime) {
        this.prevTime = parsedCurrentTime;
        this.broadcastNativeEvent(e.type, {
          duration,
          currentTime: parsedCurrentTime
        });
      }
    }
  };

  _onWaiting = (e) => {
    if (this.broadcastNativeEvent) {
      this.broadcastNativeEvent(e.type, e);
    }
  };

  _onSeeking = (e) => {
    if (this.broadcastNativeEvent) {
      this.broadcastNativeEvent(e.type, e);
    }
  };

  _onSeeked = (e) => {
    if (this.broadcastNativeEvent) {
      this.broadcastNativeEvent(e.type, e, {
        seekStartTime: this.seekStartTime
      });
      this.seekStartTime = this.currentTime();
    }
  };

  _onPlaying = (e) => {
    if (this.broadcastNativeEvent) {
      this.broadcastNativeEvent(e.type, e);
    }
  };

  _onEnded = (e) => {
    if (e && e.type === "ended") {
      this.broadcastNativeEvent(e.type, {
        currentTime: this.currentTime()
      });
    }
  };

  _onPlaybackError = (e) => {
    if (this.broadcastNativeEvent) {
      this.broadcastNativeEvent(e.type, e);
    }
  };

  _onLoadStart = (e) => {
    if (this.broadcastNativeEvent) {
      this.isFirstPlayReceive = false;
      this.broadcastNativeEvent(e.type, e);
    }
  };

  _onProgress = (e) => {
    if (this.broadcastNativeEvent) {
      this.broadcastNativeEvent(e.type, {
        duration: this.playerDom.duration,
        currentTime: this.playerDom.currentTime
      });
    }
  };

  _onSuspend = (e) => {
    if (this.broadcastNativeEvent) {
      this.broadcastNativeEvent(e.type, e);
    }
  };

  _onDurationChange = (e) => {
    if (this.broadcastNativeEvent) {
      this.broadcastNativeEvent(e.type, e);
      if (!this.isFirstDurationChangeSent) {
        this.broadcastNativeEvent(NATIVE_EVENTS.FIRST_DURATION_CHANGE, e);
        this.isFirstDurationChangeSent = true;
      }
    }
  };

  _onVolumeChange = (e) => {
    if (this.broadcastNativeEvent) {
      this.broadcastNativeEvent(e.type, e);
    }
  };

  _onRateChange = (e) => {
    if (this.broadcastNativeEvent) {
      this.broadcastNativeEvent(e.type, e);
    }
  };

  _onAbort = (e) => {
    if (this.broadcastNativeEvent) {
      this.broadcastNativeEvent(e.type, e);
    }
  };

  _onResize = (e) => {
    if (this.broadcastNativeEvent) {
      this.broadcastNativeEvent(e.type, e);
    }
  };

  _onStalled = (e) => {
    if (this.broadcastNativeEvent) {
      this.broadcastNativeEvent(e.type, e);
    }
  };

  _onQualityChanged = () => {
    try {
      const selected = this.getSelectedQualityLevel();
      if (selected) {
        this.broadcastCustomEvent(CUSTOM_EVENTS.BITRATE_CHANGED, {
          bitrate: selected.bitrate
        });
      }
    } catch (e) {
      logger.log(e);
    }
  };

  play = (playSource) => {
    if (this.playerDom) {
      this.playSource = playSource;
      const playPromise = this.playerDom.play();
      if (playPromise !== undefined) {
        playPromise.then().catch((e) => logger.error(e));
        return playPromise;
      }
    }
    return null;
  };

  pause = (pauseSource) => {
    if (this.playerDom) {
      this.pauseSource = pauseSource;
      this.playerDom.pause();
    }
  };

  forward = (time) => {
    if (this.playerDom) {
      this.playerDom.currentTime += time;
    }
  };

  rewind = (time) => {
    if (this.playerDom) {
      this.playerDom.currentTime -= time;
    }
  };

  seek = (time) => {
    this.seekStartTime = this.currentTime();
    if (this.playerRef) {
      this.playerRef.currentTime(time);
    } else if (this.playerDom) {
      this.playerDom.currentTime = time;
    }
  };

  stop = () => {
    this.destroy();
  };

  destroy = () => {
    if (
      this.playerRef &&
      (typeof this.playerRef?.isDisposed !== "undefined"
        ? !this.playerRef?.isDisposed()
        : true)
    ) {
      this.playerRef.dispose();
      this.playerRef = null;
    }
    this._unbindPlayerEvents();
  };

  muted = (value) => {
    if (this.playerDom) {
      if (typeof value === "boolean") {
        this.playerDom.muted = value;
      }
      return this.playerDom.muted;
    }
    return null;
  };

  paused = () => {
    if (this.playerDom) {
      return this.playerDom.paused;
    }
    return null;
  };

  buffered = () => {
    if (this.playerDom) {
      return this.playerDom.buffered;
    }
    return null;
  };

  duration = () => {
    if (this.playerRef) {
      return this.playerRef.duration();
    }
    if (this.playerDom) {
      return this.playerDom.duration;
    }
    return null;
  };

  currentTime = () => {
    if (this.playerRef) {
      return this.playerRef.currentTime();
    }
    if (this.playerDom) {
      return this.playerDom.currentTime;
    }
    return null;
  };

  getBuffered = () => {
    if (this.playerRef) {
      return (
        this.playerRef.buffered(0).end(0) - this.playerRef.buffered(0).start(0)
      );
    }
    return null;
  };

  setVolume = (volume, volumeSource) => {
    if (this.playerDom) {
      this.volumeSource = volumeSource;
      this.playerDom.volume = volume;
      return this.playerDom.volume;
    }
    return null;
  };

  getVolume = () => {
    if (this.playerDom) {
      return this.playerDom.volume;
    }
    return null;
  };

  setAudioTrack = (id) => {
    if (this.playerRef) {
      const audioTracks = this.playerRef.audioTracks().tracks_;
      for (let i = 0; i < audioTracks.length; i++) {
        const audioTrack = audioTracks[i];
        if (audioTrack.id === id) {
          audioTrack.enabled = true;
        }
      }
    }
    return null;
  };

  getAudioTracks = () => {
    const audioTracksArr = [];
    if (this.playerRef) {
      const audioTracks = this.playerRef.audioTracks().tracks_;
      for (let i = 0; i < audioTracks.length; i++) {
        const audioTrack = audioTracks[i];
        audioTracksArr.push({
          id: audioTrack.id,
          kind: audioTrack.kind,
          label: audioTrack.label,
          language: audioTrack.language,
          enabled: audioTrack.enabled
        });
      }
      return audioTracksArr;
    }
    return audioTracksArr;
  };

  getActiveAudioTrack = () => {
    if (this.playerRef) {
      const audioTracks = this.getAudioTracks();
      if (audioTracks) {
        if (audioTracks.length >= 1) {
          for (let i = 0; i < audioTracks.length; i++) {
            if (audioTracks[i].enabled) {
              return audioTracks[i].language.toLowerCase();
            }
          }
        }
      }
    }
    return null;
  };

  setQualityLevel = (bitrate) => {
    if (this.playerRef) {
      const qualityLevels = this.playerRef.qualityLevels().levels_;
      for (let i = 0; i < qualityLevels.length; i++) {
        const qualityLevel = qualityLevels[i];
        if (qualityLevel.bitrate > bitrate) {
          qualityLevel.enabled = false;
        } else {
          qualityLevel.enabled = true;
        }
      }
    }
    return null;
  };

  getQualityLevels = () => {
    if (this.playerRef) {
      const qualityLevels = this.playerRef.qualityLevels().levels_;
      const qualityLevelsArr = [];
      for (let i = 0; i < qualityLevels.length; i++) {
        const qualityLevel = qualityLevels[i];
        qualityLevelsArr.push({
          bitrate: qualityLevel.bitrate,
          height: qualityLevel.height,
          width: qualityLevel.width,
          enabled: qualityLevel.enabled
        });
      }
      qualityLevelsArr.sort((a, b) => a.bitrate - b.bitrate);
      return qualityLevelsArr;
    }
    return null;
  };

  getActiveQualityLevel = () => {
    if (this.playerRef) {
      return this.playerRef.qualityLevels().levels_[
        this.playerRef.qualityLevels().selectedIndex
      ];
    }
    return null;
  };

  seekToLiveEdge() {
    if (this.playerRef && this.playerRef.liveTracker) {
      this.playerRef.liveTracker.seekToLiveEdge();
    }
  }
}
