import PlayerWrapper from "./player/core/player";
import InteractorMethods from "./interactor.methods";
import EventManager from "./player/services/eventManager";
import {
  CUSTOM_EVENTS,
  EVENT_CALLBACK,
  NATIVE_EVENTS
} from "./player/ui/player/player.events";
import DataFormatter from "./player/utils/player.dataFormatter";
import { logger } from "./player/utils/player.utils";
import PlayerUI from "./player/ui/player";

export default class PlayerInteractor extends InteractorMethods {
  constructor(options) {
    super();
    this.options = options;
    this.eventManager = new EventManager();
    this.subscribeToEvents();
    this.init(this.options);
  }

  static get listPlayerEvents() {
    return {
      NATIVE_EVENTS,
      CUSTOM_EVENTS
    };
  }

  init(options) {
    const { playerMetadata } = options;
    this.setDataModel(playerMetadata);
    this.subscribeToEventsFe(playerMetadata);
    this.getLicense()
      .then((drmResponse) => {
        const { url, isEncrypted, licenseUrl, customHeaders } = this.dataModel;
        const { autoplay, muted, preload, videoId, videoWrapperID } = options;
        const config = {
          url,
          muted,
          preload,
          videoId,
          autoplay,
          isEncrypted,
          customHeaders,
          interactor: this,
          videoPlayerWrapper: document.getElementById(videoWrapperID),
          licenseUrl: licenseUrl || drmResponse?.resultObj?.laURL || ""
        };
        this.playerObj = new PlayerWrapper(config);
        // this.playerUI = new PlayerUI(config);
      })
      .catch((err) => {
        logger.log(err);
      });
  }

  setDataModel = (playerMetadata) => {
    const dataFormatter = new DataFormatter(playerMetadata);
    this.dataModel = dataFormatter.dataModel;
  };

  getLicense = () => {
    const promise = new Promise((resolve, reject) => {
      if (this.dataModel.isEncrypted) {
        try {
          resolve();
        } catch (error) {
          reject();
        }
        return;
      }
      resolve(null);
    });
    return promise;
  };

  // eslint-disable-next-line class-methods-use-this
  adCallback = (eventName, eventData) => {
    // logger.log(eventName, eventData);
    // switch (eventName) {
    //   case NATIVE_EVENTS.LOADEDDATA:
    //     break;
    // }
  };

  playerCallback = (eventName, eventData) => {
    // if (eventName !== "seekbarupdate") {
    //   logger.log(eventName, eventData);
    // }
    const playContent = () => {
      if (this.options.autoplay) {
        this.playerObj.play();
      }
      // setTimeout(() => {}, 2000);
    };
    switch (eventName) {
      case NATIVE_EVENTS.LOADEDDATA:
        playContent();
        break;
      case NATIVE_EVENTS.FIRST_DURATION_CHANGE:
        playContent();
        break;
    }
  };

  // eslint-disable-next-line class-methods-use-this
  playerCustomCallback = (eventName, eventData) => {
    // logger.log(eventName, eventData);
    switch (eventName) {
      case CUSTOM_EVENTS.BITRATE_CHANGED:
        break;
    }
  };

  subscribeToEvents() {
    this.eventManager.subscribeToEvents({
      eventType: EVENT_CALLBACK.AD_EVENTS,
      eventCallback: this.adCallback.bind(this),
      senderName: "PlayerInteractor"
    });
    this.eventManager.subscribeToEvents({
      eventType: EVENT_CALLBACK.NATIVE_EVENTS,
      eventCallback: this.playerCallback.bind(this),
      senderName: "PlayerInteractor"
    });
    this.eventManager.subscribeToEvents({
      eventType: EVENT_CALLBACK.CUSTOM_EVENTS,
      eventCallback: this.playerCustomCallback.bind(this),
      senderName: "PlayerInteractor"
    });
  }

  subscribeToEventsFe(playerMetadata) {
    const { adCallback, playerCallback, playerCustomCallback } = playerMetadata;
    const config = {
      adCallback,
      playerCallback,
      playerCustomCallback,
      subscriberName: "FE"
    };
    this.subscribeExternal(config);
  }

  subscribeExternal = (options) => {
    const { playerCallback, playerCustomCallback, adCallback, subscriberName } =
      options;
    if (adCallback) {
      this.eventManager.subscribeToEvents({
        eventType: EVENT_CALLBACK.AD_EVENTS,
        eventCallback: adCallback,
        senderName: subscriberName
      });
    }
    if (playerCallback) {
      this.eventManager.subscribeToEvents({
        eventType: EVENT_CALLBACK.NATIVE_EVENTS,
        eventCallback: playerCallback,
        senderName: subscriberName
      });
    }
    if (playerCustomCallback) {
      this.eventManager.subscribeToEvents({
        eventType: EVENT_CALLBACK.CUSTOM_EVENTS,
        eventCallback: playerCustomCallback,
        senderName: subscriberName
      });
    }
  };

  destroy = () => {
    this.eventManager.broadcastCustomEvent(CUSTOM_EVENTS.DESTROY, {});
    this.playerObj?.destroy();
    this.playerUI?.destroy();
  };
}
