export const EVENT_CALLBACK = {
  NATIVE_EVENTS: "NATIVE_EVENTS",
  CUSTOM_EVENTS: "CUSTOM_EVENTS",
  AD_EVENTS: "AD_EVENTS"
};

export const NATIVE_EVENTS = {
  ABORT: "abort",
  CANPLAY: "canplay",
  CANPLAYTHROUGH: "canplaythrough",
  DURATIONCHANGE: "durationchange",
  EMPTIED: "emptied",
  ENDED: "ended",
  ERROR: "error",
  LOADEDDATA: "loadeddata",
  LOADEDMETADATA: "loadedmetadata",
  LOADSTART: "loadstart",
  PAUSE: "pause",
  PLAY: "play",
  PLAYING: "playing",
  PROGRESS: "progress",
  RATECHANGE: "ratechange",
  RESIZE: "resize",
  SEEKED: "seeked",
  SEEKING: "seeking",
  STALLED: "stalled",
  SUSPEND: "suspend",
  TIMEUPDATE: "timeupdate",
  VOLUMECHANGE: "volumechange",
  WAITING: "waiting",
  INIT: "init",
  DESTROY: "destroy",
  SEEKBAR_UPDATE: "seekbarupdate",
  FIRST_DURATION_CHANGE: "FIRST_DURATION_CHANGE",
  AUDIO_CHANGE: "AUDIO_CHANGE",
  EBVS_NO: "ebvs_no",
  EBVS_YES: "ebvs_yes"
};

export const CUSTOM_EVENTS = {
  BITRATE_CHANGED: "BITRATE_CHANGED",
  TIMEDMETADATA: "TIMEDMETADATA",
  DESTROY: "DESTROY"
};
