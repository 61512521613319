import { logger } from "../utils/player.utils";

/* eslint-disable */
export default class PlayerInterface {
  log() {
    logger.warn("PlayerInterface");
  }

  init = this.log;

  play = this.log;

  pause = this.log;

  forward = this.log;

  rewind = this.log;

  seek = this.log;

  stop = this.log;

  destroy = this.log;

  muted = this.log;

  paused = this.log;

  buffered = this.log;

  duration = this.log;

  currentTime = this.log;

  getBuffered = this.log;

  setVolume = this.log;

  getVolume = this.log;

  setAudioTrack = this.log;

  getAudioTracks = this.log;

  getActiveAudioTrack = this.log;

  setQualityLevel = this.log;

  getQualityLevels = this.log;

  getActiveQualityLevel = this.log;
}
